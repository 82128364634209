import React, { useState } from "react";
import { LyricsObject } from "./App";
import LyricsSelector from "./LyricsSelector";
import { Button } from "react-bootstrap";
import "./SongBlurbCard.scss";
import "./App.scss";
export interface songBlurbCardProps {
  songBlurb: string;
  open: boolean;
  isLoadingLyrics: boolean;
}

// for the lyrics selector thing

const SongBlurbCard = (props: songBlurbCardProps) => {
  const [show, setShow] = useState(false);

  //   const handleClose = () => {
  //     setShow(false), props.setModalOpen(false);
  //   };
  //   const handleShow = () => {
  //     setShow(true), props.setModalOpen(true);
  //   };

  return props.open ? (
    <div className="song-blurb-card">
      {!props.isLoadingLyrics? (
          props.songBlurb  && props.songBlurb.trim() ? (
        <p>{props.songBlurb.trim()}</p>
          ): (
        <p>No description available.</p>
          )
        // <p>{props.songBlurb}</p>
      ) : (
        <div className="spinner blurb-spinner"></div>
      )}
    </div>
  ) : (
      null
  )
};

export default SongBlurbCard;
