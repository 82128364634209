import React, { useState } from "react";
import './AlbumTitleCard.scss'
export interface AlbumTitleCardProps {
  albumName: string;
}

const AlbumTitleCard = (props: AlbumTitleCardProps) => {
  //do stuff

  return (
    <div>
        <h5>Album <b>{props.albumName} </b> </h5>
    </div>
  );
};

export default AlbumTitleCard;
