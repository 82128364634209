import React, { useState } from "react";
import RefreshButton from "./RefreshButton";
import { checkPropTypes } from "prop-types";

export interface headingProps {
	controlPlaybackButtons: (event: any) => void;
	palette:any;
	isLoggedIn:boolean;
}

const Heading = (props: headingProps) => {


	const headingColor = props.isLoggedIn ? 'whitesmoke': ''

	const divStyle ={
		color: headingColor,
		textShadow:`-6px 3px 0 ${props.palette.vibrant}, -12px 7px 0 #0a0e27`,
	}

	return (
		<div className="app-heading">
			<div className='heading' style={divStyle} id='heading'> Spotify Lyrics
			<RefreshButton controlPlaybackButtons={props.controlPlaybackButtons} />
			</div>
			{/* need conditional rendering here, once logged in only.... */}
		</div>
	);
};

export default Heading;