import React, { useState } from "react";
import "./Player.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AlbumCard from "./AlbumCard";
import SongInfoCard from "./SongInfoCard";
import ProgressCard from "./ProgressCard";
import RefreshButton from "./RefreshButton";
import NavigationContainer from "./NavigationContainer";
import { prependOnceListener } from "cluster";

export interface playerProps {
  controlPlaybackButtons: (event: any) => void;
  item: any;
  songInfo: {
    albumImg: string;
    albumTitle: string;
    songTitle: string;
    artists: string[];
    songLength: number;
  };

  lyrics: string;
  isLoadingSongInfo: boolean;
  isLoadingLyrics: boolean;
  toggleBlurbOpen: any;
  isSongBlurbOpen: any;
}

const Player = (props: playerProps) => {
  return (
    <div className="player shadow-lg">
      <Container className="player-container">
        <Row className="song-info-top-row">
          <SongInfoCard
            songTitle={props.songInfo.songTitle}
            artists={props.songInfo.artists}
            albumName={props.songInfo.albumTitle}
          />
            <img
              className="info-button"
              src="./info.svg"
              onClick={props.toggleBlurbOpen}
            />
        </Row>
        <NavigationContainer
          item={props.item}
          controlPlaybackButtons={props.controlPlaybackButtons}
        />
      </Container>
    </div>
  );
};

export default Player;
