import * as React from "react";
import { useState, useEffect } from "react";
import "./App.scss";
import Player from "./Player";
import io from "socket.io-client";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LyricsCard from "./LyricsCard";
import AlbumCard from "./AlbumCard";
import Heading from "./Heading";
import SongBlurbCard from "./SongBlurbCard";
import { usePalette } from "react-palette";
import pageExample from "./example.png";

const API_URL = process.env.REACT_APP_API_URL || "";
const socket = io.connect(API_URL);

export interface LyricsObject {
  url: string;
  lyrics: string;
  blurb: string;
}
interface hashParamType {
  access_token: string;
  refresh_token: string;
}

function App() {
  const [item, setItem] = useState();
  const [isLoadingSongInfo, setIsLoadingSongInfo] = useState(true);
  const [isLoadingLyrics, setIsLoadingLyrics] = useState(true);
  const [isSongBlurbOpen, setSongBlurbOpen] = useState(false);
  const [lyricOptions, setLyricOptions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [palette, setPalette] = useState({ vibrant: "", muted: "" });
  const [songInfo, setSongInfo] = useState({
    albumImg: "",
    albumTitle: "",
    songTitle: "",
    artists: [""],
    songLength: 0,
  });
  const [lyricsObject, setLyrics] = useState({
    url: "",
    lyrics: "",
    blurb: "",
  });

  const setCurrentlyPlaying = (songinfo: any, response: any) => {
    setItem(response);
    let artistArray: string[] = [];
    response.item.artists.forEach((element: { name: string }) => {
      artistArray.push(element.name);
    });
    setSongInfo({
      albumImg: response.item.album.images[0].url,
      albumTitle: response.item.album.name,
      songTitle: response.item.name,
      artists: artistArray,
      songLength: response.item.duration_ms,
    });
    setIsLoadingSongInfo(false);
  };

  const { data, loading, error } = usePalette(songInfo.albumImg); // this has a useEffect inside it
  if (data != palette) {
    setPalette(data);
  }

  /**
   * this stays open and running, we keep on getting data from server through the socket
   * TODO add more explanation
   */
  useEffect(() => {
    // waiting for login data
    socket.on("login data", (data: any) => {
      setIsLoggedIn(data.body);
    });
    // waiting on spotify data
    socket.on("spotify data", (data: any) => {
      let response = data.body;
      if (Object.keys(response).length === 0) {
        setIsLoadingSongInfo(true);
      } else if (
        songInfo.songTitle !== response.item.name &&
        songInfo.albumTitle !== response.item.album.name
      ) {
        setCurrentlyPlaying(songInfo, response);
      }
    });

    socket.on(
      "genius data",
      (newLyricsObject: LyricsObject, searchTitle: string) => {
        setLyrics(newLyricsObject);
      }
    );

    // waiting on lyric data direct from URL (no name check)
    socket.on("genius url", (newLyricsObject: LyricsObject) => {
      setLyrics(newLyricsObject);
    });

    socket.on("lyric options", (data: any) => {
      // we have to pass this to our lyric component
      setLyricOptions(data);
    });

    return () => {
      socket.off("spotify data");
      socket.off("genius data");
      socket.off("genius url data");
      socket.off("lyric options");
      socket.off("songBlurb");
    };
  }, []);

  useEffect(() => {
    setIsLoadingLyrics(true);
  }, [songInfo]);

  useEffect(() => {
    setIsLoadingLyrics(false);
  }, [lyricsObject]);

  useEffect(() => {
    if (palette.muted) {
      document.body.style.backgroundColor = palette.muted;
    }
  }, [palette]);

  const controlPlaybackButtons = (event: any) => {
    const action = event.target.className;
    switch (action) {
      case "refresh-button-container":
        socket.emit("navigation", "refresh");
        break;
      case "scrubForward":
        socket.emit("navigation", "scrubForward");
        break;
      case "scrubBackward":
        socket.emit("navigation", "scrubBackward");
        break;
      case "skip":
        socket.emit("navigation", "skip");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      case "play-pause":
        socket.emit("navigation", "playPause");
        break;
      case "prev":
        socket.emit("navigation", "prev");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
    }
  };

  const controlPlaybackKeyboard = (event: KeyboardEvent) => {
    // console.log("MODAL OPEN", modalOpen, songInfo);
    if (modalOpen) return;
    switch (event.key) {
      case "Enter":
        socket.emit("navigation", "refresh");
        break;
      case "ArrowRight":
        socket.emit("navigation", "scrubForward");
        break;
      case "ArrowLeft":
        socket.emit("navigation", "scrubBackward");
        break;
      case "c":
        socket.emit("navigation", "skip");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      case "x":
        socket.emit("navigation", "playPause");
        break;
      case "z":
        socket.emit("navigation", "prev");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
    }
  };

  const toggleBlurb = () => {
    if (isSongBlurbOpen) {
      setSongBlurbOpen(false);
    } else {
      setSongBlurbOpen(true);
    }
  };

  // keyboard nav effect
  useEffect(() => {
    window.addEventListener("keydown", controlPlaybackKeyboard);

    return () => {
      window.removeEventListener("keydown", controlPlaybackKeyboard);
    };
  }, [modalOpen]);

  return (
    <div className="everything">
      <div className="App">
        <Container fluid={true}>
          <Heading
            controlPlaybackButtons={controlPlaybackButtons}
            palette={palette}
            isLoggedIn={isLoggedIn}
          />
          {!isLoggedIn ? (
            <React.Fragment>
              <br></br>
              <br></br>
              <div className="landing-page-sales-text">
                Automatically grabs the lyrics for whatever song you're
                listening to on Spotify.
                <img src={pageExample}></img>
              </div>
              <br></br>
              <a className="btn btn--loginApp-link" href={`${API_URL}/login`}>
                Try it out!{" "}
              </a>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isLoadingSongInfo ? (
                <div>
                  You're logged in, but not playing anything 😅<br></br> Start
                  playing a song on Spotify, then come back here and click
                  refresh. If you're still having issues, close and reopen
                  Spotify.
                </div>
              ) : (
                <div>
                  <Row>
                    <Col xs={6}></Col>
                    <Col xs={6}></Col>
                    <br></br>
                    <br></br>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="info-panel">
                        <Player
                          item={item}
                          songInfo={songInfo}
                          lyrics={lyricsObject.lyrics}
                          isLoadingLyrics={isLoadingLyrics}
                          isLoadingSongInfo={isLoadingSongInfo}
                          controlPlaybackButtons={controlPlaybackButtons}
                          toggleBlurbOpen={toggleBlurb}
                          isSongBlurbOpen={isSongBlurbOpen}
                        />
                        <SongBlurbCard
                          songBlurb={lyricsObject.blurb}
                          open={isSongBlurbOpen}
                          isLoadingLyrics={isLoadingLyrics}
                        />
                        <AlbumCard
                          albumImg={songInfo.albumImg}
                          palette={palette}
                        ></AlbumCard>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <LyricsCard
                        setModalOpen={setModalOpen}
                        setIsLoadingLyrics={setIsLoadingLyrics}
                        lyricsObject={lyricsObject}
                        lyricOptions={lyricOptions}
                        isLoading={isLoadingLyrics}
                      />
                      <br></br>
                      <br></br>
                    </Col>
                  </Row>
                  <Row>
                    <div></div>
                  </Row>
                </div>
              )}
            </React.Fragment>
          )}
        </Container>
      </div>
    </div>
  );
}

export default App;
