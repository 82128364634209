import React, { useState } from "react";
import "./Player.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AlbumCard from "./AlbumCard";
import SongInfoCard from "./SongInfoCard";
import ProgressCard from "./ProgressCard";
import { register } from "./serviceWorker";
import { checkPropTypes } from "prop-types";

export interface refreshButtonProps {
  //TODO change this type
  controlPlaybackButtons: (event: any) => void;
  // skipTrack: () => any ; 
  // prevTrack: () => any ; 
  // pauseTrack: () => any ; 
  // scrubForwardTrack: () => any ; 
  // scrubBackwardTrack: () => any ; 
}

const RefreshButton = (props: refreshButtonProps) => {
  return (
        <button className='refresh-button-container' onClick={props.controlPlaybackButtons}>
            <img className="nav-button refresh-button" src='./refresh.svg' ></img>
        </button>
  );
};
export default RefreshButton;