import React, { useState, useRef, useEffect } from "react";
import { LyricsObject } from "./App";
import { Button, Modal, Form } from "react-bootstrap";
import LyricsOptionRow from "./lyricOptionRow";
import socketIOClient from "socket.io-client";
export interface lyricsSelectorProps {
  lyricsOptions: any;
  show: boolean;
  setIsLoadingLyrics: any
  handleClose: () => any;
  handleShow: () => any;
}
const API_URL = process.env.REACT_APP_API_URL || "";
const socket = socketIOClient(API_URL);

const LyricsSelector = (props: lyricsSelectorProps) => {
  const startSearch: any = (a: any) => {
    // console.log(a.target.value);
    // send this over "song and artist or smth"
    socket.emit("search", a.target.value);
  };

  const grabLyrics = (url: string) => {
    // side effect, lyrics are loading. 
    props.setIsLoadingLyrics(true);

    socket.emit("genius url", url);
  };

  // map the arrays to nice lists
  let list = props.lyricsOptions.map((arr: any) => {
    return (
      <LyricsOptionRow
        img={arr.img}
        title={arr.fullTitle}
        url={arr.url}
        grabLyrics={grabLyrics}
        handleClose={props.handleClose}
      ></LyricsOptionRow>
    );
  });
  return (
    <>
      <Modal
        autoFocus={false}
        show={props.show}
        onHide={props.handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* //@ts-ignore */}
            <Form.Control
              autoFocus={true}
              className="search-bar"
              type="text"
              onChange={() => startSearch(event)}
              placeholder="Search through Genius"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> {list} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LyricsSelector;
