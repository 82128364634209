import React, { useState } from "react";
import "./AlbumCard.scss";
import Palette from "react-palette";

export interface albumCardProps {
  albumImg: string;
  palette: any;
}

const AlbumCard = (props: albumCardProps) => {
  //do stuff
  const divStyle = {
    backgroundColor: props.palette.vibrant
  };
  return (
    <div
      className="albumArt-card shadow-lg"
      style={divStyle}
      id="album-art-card"
    >
      <img className="shadow-lg" src={props.albumImg} />
    </div>
  );
};

export default AlbumCard;
