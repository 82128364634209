import React, { useState } from "react";
import SongTitleCard from "./SongTitleCard";
import ArtistTitleCard from "./ArtistTitleCard";
import AlbumTitleCard from "./AlbumTitleCard";
import AlbumCard from "./AlbumCard";
import "./SongInfoCard.scss";

export interface songInfoCardProps {
  songTitle: string;
  artists: string[];
  albumName: string;
}

const SongInfoCard = (props: songInfoCardProps) => {
  //do stuff
  return (
    <div className="song-info-card">
        <SongTitleCard songTitle={props.songTitle}/>
        <ArtistTitleCard artists={props.artists}/>
        <AlbumTitleCard albumName={props.albumName}/>

    </div>
  );
};

export default SongInfoCard;
