import React, { useState } from "react";
import "./ArtistTitleCard.scss";

export interface artistTitleCardProps {
  artists: string[];
}

const ArtistTitleCard = (props: artistTitleCardProps) => {
  //do stuff

  return (
    <div>
      <h3 className="artist-title-card"> {props.artists.join(', ')}</h3>
    </div>
  );
};

export default ArtistTitleCard;
