import React, { useState } from "react";
import { LyricsObject } from "./App";
import { Button, Modal } from "react-bootstrap";

export interface lyricsOptionRowProps {
  img: any;
  title: string;
  grabLyrics: (url: string) => any;
  handleClose: () => any;
  url: string;
}

const LyricsOptionRow = (props: lyricsOptionRowProps) => {

  // console.log("These are some urls", props.url);
  return (
    <button className="lyric-option-row" onClick={() => {props.grabLyrics(props.url); props.handleClose()}}>
      <img className="thumbnail" src={props.img}></img>
      <div className="lyric-option-title"> {props.title} </div>
    </button>
  );
};

export default LyricsOptionRow;
