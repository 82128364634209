import React, { useState, useRef } from "react";
import "./Player.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AlbumCard from "./AlbumCard";
import SongInfoCard from "./SongInfoCard";
import ProgressCard from "./ProgressCard";
import { register } from "./serviceWorker";
import { checkPropTypes } from "prop-types";

export interface navigationContainerProps {
  item: any;
  //TODO change this type
  controlPlaybackButtons: (event: any) => void;

}


const NavigationContainer = (props: navigationContainerProps) => {
  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  return (
    <div className="navigation-container">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-prev`}>Z</Tooltip>}
      >
        <button
          className="prev"
          data-toggle="tooltip"
          data-placement="top"
          title="Tooltip on top"
          onClick={props.controlPlaybackButtons}
        >
          <img className="nav-button prev-button" src="./previous.svg"></img>
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-back`}>←</Tooltip>}
      >
        <button
          className="scrubBackward"
          onClick={props.controlPlaybackButtons}
        >
          <img
            className="nav-button scrubBackward-button"
            src="./backward.svg"
          ></img>
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-play-pause`}>X</Tooltip>}
      >
        <button className="play-pause" onClick={props.controlPlaybackButtons}>
          <div className="nav-button play-pause-button">
            <img className="nav-button play-button" src="./play.svg" />
            <img className="nav-button pause-button" src="./pause.svg" />
          </div>
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-forward`}>→</Tooltip>}
      >
        <button className="scrubForward" onClick={props.controlPlaybackButtons}>
          <img
            className="nav-button scrubForward-button"
            src="./forward.svg"
          ></img>
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-skip`}>C</Tooltip>}
      >
        <button className="skip" onClick={props.controlPlaybackButtons}>
          <img className="nav-button skip-button" src="./next.svg"></img>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default NavigationContainer;
