import React, { useState } from "react";
import "./SongTitleCard.scss";

export interface songTitleCardProps {
    songTitle: string;
}

const SongTitleCard = (props: songTitleCardProps) => {
    //do stuff

    return (
        <div>
            <h3 className="song-title-card">{props.songTitle}</h3>
        </div>
    );
};

export default SongTitleCard;
