import React, { useState } from "react";
import "./LyricsCard.scss";
import { LyricsObject } from "./App";
import LyricsSelector from "./LyricsSelector";
import { Button } from "react-bootstrap";

export interface lyricsCardProps {
  lyricsObject: LyricsObject;
  lyricOptions: any;
  setIsLoadingLyrics: any;
  isLoading: boolean;
  setModalOpen: any
}

// for the lyrics selector thing

const LyricsCard = (props: lyricsCardProps) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false), props.setModalOpen(false)};
  const handleShow = () => {setShow(true), props.setModalOpen(true)};

  return (
    <div className="lyrics-card ">
      {props.isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className="lyrics-div shadow-lg">
          <LyricsSelector
            lyricsOptions={props.lyricOptions}
            show={show}
            setIsLoadingLyrics={props.setIsLoadingLyrics}
            handleClose={handleClose}
            handleShow={handleShow}
          ></LyricsSelector>
          {props.lyricsObject.url != "" && (
            <p className="genius-url">
              Full lyrics + annotations&nbsp;
              <a target="_blank" href={props.lyricsObject.url}>
                here
              </a>
            </p>
          )}
          <p>
          <Button className="other-lyrics-button"variant="primary" onClick={handleShow}>
            Wrong Lyrics? 
          </Button>
          </p>
          {props.lyricsObject.lyrics}
        </div>
      )}
    </div>
  );
};

export default LyricsCard;
